html,
#root,
body
{
height:100%;
}


/* grid */
#gridContainer {
	display: grid;
	height:100%;
	width:90%;
	float:left;
	background-color:#ab8b74;
}
.grid-item {
	margin:1px;
}
.grid-item.alive {
	background-color:#ffe4a3;
}
.grid-item.dead {
	background-color:#765848;
}

/* panel */
#sidePanel {
	width:10%;
	height:100%;
	position:absolute;
	right:0px;
	background-color:#ab8b74;
}
.button {
	background-color: #ffe4a3;
	margin-bottom: 5px;
	border: none;
	color: black;
	padding: 15px 0px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	width: 100%;
}
.button.label {
	margin-bottom: 0px;
	padding-bottom: 0px;
}
.button.half {
	width: 50%;
}
